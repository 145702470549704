<template>
  <div :is="layout">
    <v-container fluid>
      <v-row>
        <v-col cols="3">
          <v-text-field
              :label="$t('system.search_text')"
              v-model="search_info.username"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-btn large color="primary" @click="getUserList(1)">{{
              $t("search")
            }}
          </v-btn>
        </v-col>
      </v-row>
      <v-data-table :headers="headers" :items="desserts"
                    :server-items-length="list_count"
                    :items-per-page="10"
                    :loading-text="$t('data_loading')"
                    :loading="table_loading"
                    @pagination="changePagination"
                    class="elevation-1"
                    :footer-props="{
                    showFirstLastPage: true,
        }"
      >
        <template v-slot:item.groups="{item}">
          {{ groupText(item.groups[0]) }}
        </template>
        <template v-slot:item.action="{item}">
          <v-btn small color="primary" text @click="edit_open(item)"
          >{{ $t('edit') }}
          </v-btn>
          <v-btn small color="primary" text @click="del(item)">{{ $t('delete') }}</v-btn>
        </template>
      </v-data-table>
    </v-container>
    <v-btn @click="add_open" color="primary" fab class="float-btn">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <v-dialog v-model="dialog" width="800px">
      <v-card>
        <v-card-title>
          {{ $t(id ? 'system.edit_user' : 'system.add_user') }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col :cols="6">
              <v-text-field :label="$t('system.name')" v-model="info.username">

              </v-text-field>
            </v-col>
            <v-col :cols="6">
              <v-text-field :label="$t('system.email')" v-model="info.email">

              </v-text-field>
            </v-col>
            <v-col :cols="6">
              <v-select :label="$t('system.group')" return-object :items=groupType v-model="info.groups">

              </v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="submit" color="primary">{{ $t("confirm") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {user_list} from "../../../api/auth";
import {get_group} from "../../../api/permissions";

export default {
  name: "index",
  data() {
    return {
      layout: null,
      desserts: [],
      info: {},
      dialog: false,
      id: '',
      search_info: {
        pageindex: 1,
        pagesize: 10,
        username: ""
      },

      list_count: 0,
      table_loading: false,
      groupType: []
    }
  },
  mounted() {
    this.layout = this.$route.meta.use_layout
    this.getGroups()
    this.getUserList()
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("system.name"),
          align: "center",
          value: "username",
          sortable: false,
        },
        {
          text: this.$t("system.email"),
          align: "center",
          value: "email",
          sortable: false,
        },
        {
          text: this.$t("system.group"),
          align: "center",
          value: "groups",
          sortable: false,
        },
        {
          text: this.$t("system.action"),
          align: "center",
          value: "action",
          sortable: false,
        }
      ]
    },
    groups() {
      return [
        {
          text: this.$t('system.admin'),
          value: 0
        },
        {
          text: this.$t('system.teacher'),
          value: 1
        },
        {
          text: this.$t('system.student'),
          value: 2
        }
      ]
    },
    groupText() {
      return function (id) {
        if (id) {
          const {text} = this.groupType.find(item => item.value === id)
          return text
        }
      }
    },
  },
  watch:{

  },
  methods: {

    async changePagination(data) {
      this.search_info.pageindex = data.page;
      this.search_info.pagesize = data.itemsPerPage;
      this.minusPage(this.list_count)
    },

    minusPage(data) {
      let totalPage = Math.ceil((data) / this.search_info.pagesize);
      let pageindex = this.search_info.pageindex > totalPage ? totalPage : this.search_info.pageindex;
      this.search_info.pageindex = pageindex < 1 ? 1 : pageindex;
      this.getUserList()
    },

    edit_open(item) {
      this.id = item.id
      this.info = JSON.parse(JSON.stringify(item))
      if (this.info.groups.length){
        this.info.groups=this.info.groups[0]
      }else
        this.info.groups=''
      this.dialog = true
    },
    add_open() {
      this.id = ''
      this.dialog = true
    },
    del(data) {
      this.$confirm({
          title: this.$t('confirm_del'),
          content: this.$t('auth.delete_user'),
          okText: this.$t('confirm'),
          cancelText: this.$t('cancel')
      }).then((dialog) => {

      });
    },
    async getUserList(id) {
      id && (this.search_info.pageindex = id)
      this.table_loading = true;
      const {results, count} = await user_list(this.search_info)
      this.desserts = results
      this.list_count = count
      this.table_loading = false
    },
    async getGroups() {
      const {results} = await get_group()
      // const data=results.reduce((acc,cur)=>{
      //   const {id,name}=cur
      //   acc[id]=name
      //   return acc
      // },{})
      const data = results.reduce((acc, cur) => {
        const {id, name} = cur
        acc.push(
            {
              text: name,
              value: id
            }
        )
        return acc
      }, [])
      this.groupType = data
    },
    submit(){

    }
  }
}
</script>

<style lang="stylus" scoped>
@import '~@/assets/css/global.styl';
</style>
